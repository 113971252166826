

const api ={
    login : '/admin/login',
    getUser: '/admin/users?',
    updateUser : '/admin/users/',
    deleteUser : '/admin/users/',
    logoutUser : '/admin/users/logout/',
    createUser : '/admin/users',
    avatarUser : '/admin/upload/avatar/',
    filterSearchUser : '/admin/users/filterSearch?',
    filterUserAndExport : '/admin/users/filterSearchAndExportUser?',
    searchUser: '/admin/users/search?',
    getgoalUser : '/admin/users/get-goals',
    getuserByphone : '/admin/orders/phone',
    bulkUserRoleUpdate : '/admin/users/bulk-user-role',

    createGoal : '/admin/goals',
    getGoal: '/admin/goals?',
    updateGoal : '/admin/goals/',
    deleteGoal : '/admin/goals/',
    searchGoal: '/admin/goals/search?',
    getSubsGoal : '/admin/goals/get-subscriptions',

    createSubscription:'/admin/subscriptions',
    updateSubscription : '/admin/subscriptions/',
    getSubscription: '/admin/subscriptions?',
    getSubscriptionWithoutGoal : '/admin/subscriptions/get-subscriptions-without-goal',
    deleteSubscription : '/admin/subscriptions/',
    bannerSubscription : '/admin/subscriptions/uploadSubscriptionBanner/',
    searchSubscription : "/admin/subscriptions/search?",
    getgoalsub:'/admin/subscriptions/get-subscriptions?goalId=',
    getgoalsubscription:'/admin/subscriptions/get-goals',
    getCoursesSubs: '/admin/subscriptions/get-courses',
   


    createCourse: '/admin/courses',
    updateCourse: '/admin/courses/',
    deleteCourse: '/admin/courses/',
    getCourses : '/admin/courses?',
    getCourseById: '/admin/courses/',
    bannerCourse: '/admin/courses/uploadCourseBanner/',
    getCourseCourse: '/admin/courses/get-previous-courses',
    getsubjectCourse:'/admin/courses/get-subjects?courseId=',
    createsubCourse: '/admin/courses/create-subject',
    getgoalsCourse : '/admin/courses/get-goals',
    getsubsCourse: '/admin/courses/get-subscriptions?goalId=',
    getCorsCourse: '/admin/courses/get-courses?subscriptionId=',
    searchCourse: '/admin/courses/search?',
    getEducatorCourse: '/admin/courses/get-educators',
    getCourseAll : '/admin/courses/get-courses-by-subscription?subscriptionId=',
    AddNewEbook : '/admin/upload/course-ebook/',
    updateEbook : '/admin/upload/update-ebook/',
    deleteEbook : '/admin/upload/delete-ebook/',

    createSubject:'/admin/subjects',
    updateSubject : '/admin/subjects/',
    deleteSubject: '/admin/subjects/',
    getSubject   : '/admin/subjects?',
    getSubjectById: '/admin/subjects/',
    bannerSubject: '/admin/subjects/uploadSubjectBanner/',
    getgoalsubject: '/admin/subjects/get-goals',
    getsubsubject : '/admin/subjects/get-subscriptions?goalId=',
    getcourseSubject : '/admin/subjects/get-courses?subscriptionId=',
    getSubjSubject:'/admin/subjects/get-subjects?courseId=',
    searchSubject:'/admin/subjects/search?',
    createChapSubject:'/admin/subjects/create-chapter',
    updateCourseSubject : '/admin/courses/add-subject-to-course',
    



    createChapter : '/admin/chapters',
    updateChapter :'/admin/chapters/',
    deleteChapter : '/admin/chapters/',
    getChapter:     '/admin/chapters?',
    getChapterById: '/admin/chapters/',
    getGoalChap: '/admin/chapters/get-goals',
    getSubChap: '/admin/chapters/get-subscriptions?goalId=',
    getCorsChap: '/admin/chapters/get-courses?subscriptionId=',
    getSubjChap : '/admin/chapters/get-subjects?courseId=',
    getChapChapter: '/admin/chapters/get-chapters?subjectId=',
    searchChapter: '/admin/chapters/search?',
    getSinglesubj: '/admin/chapters/get-chapters-by-subject?subjectId=',
    updSubjChap:'/admin/chapters/update-subject/',

    createClass : '/admin/classes',
    updateClass : '/admin/classes/',
    deleteClass : '/admin/classes/',
    getClass : '/admin/classes?',
    getClassById: '/admin/classes/',
    classThumbnail : '/admin/classes/uploadVideoThumbnail/',
    classNote : '/admin/classes/uploadClassNote/',
    getgoalsClass:'/admin/classes/get-goals',
    getsubsClass: '/admin/classes/get-subscriptions?goalId=',
    getcoursesClass: '/admin/classes/get-courses?subscriptionId=',
    getSubjectsClass:'/admin/classes/get-subjects?courseId=',
    getChaptersClass:'/admin/classes/get-chapters?subjectId=',
    getClassOfChapter:'/admin/classes/get-classes?chapterId=',
    searchClass:'/admin/classes/search?',
    searchCoupon:'/admin/coupons/search?code=',
    getSubscriptionAddToCoupon : '/admin/coupons/subscription-addtoCoupon',
    CatalogCourseAddToCoupon : '/admin/coupons/CatalogCourse-addtoCoupon',
    uploadClassNote: '/admin/upload/classNote/',

    createCoupon: '/admin/coupons',
    updateCoupon:'/admin/coupons/',
    getCoupon: '/admin/coupons?',
    deleteCoupon :'/admin/coupons/',
    getsubcoupon:'/admin/coupons/get-subscriptions',
    getCatalogCourseCoupon : '/admin/coupons/get-catalogCourse',
    bulkCouponDelete : '/admin/coupons/bulk-coupon-delete',
    bulkCouponUpdate : '/admin/coupons/bulk-coupon-update',

    getAllCoupon : '/admin/coupons/get-all-coupon',
    getBrandPartnerCoupon: '/admin/coupons/brandpartner-coupons',
    getEducatorCoupon: '/admin/coupons/educator-coupons',

    searchMcqExam : '/admin/mcq-exams/search?',
    getMcqExam: '/admin/mcq-exams',
    createMcqExam : '/admin/mcq-exams/',
    updateMcqExam : '/admin/mcq-exams/',
    deleteMcqExam : '/admin/mcq-exams/',
    viewAllQuestion: '/admin/mcq-exams/review-questions/',
    getGoalMcqExam: '/admin/mcq-exams/get-goals',
    getSubscriptionMcqExam:'/admin/mcq-exams/get-subscriptions?goalId=',
    getCoursesMcqExam: '/admin/mcq-exams/get-courses?subscriptionId=',
    getSubjectsMcqExam: '/admin/mcq-exams/get-subjects?courseId=',
    getChaptersMcqExam : '/admin/mcq-exams/get-chapters?subjectId=',
    getClassesMcqExam:'/admin/mcq-exams/get-classes?chapterId=',
    getTestMcqExam:'/admin/mcq-exams/get-tests/',
    getQuizMcqExam:'/admin/mcq-exams/get-quiz/',
    getQuizOfaClass : '/admin/mcq-exams/get-class-of-quiz?',

    createMcq : '/admin/mcq-questions/',
    updateMcq : '/admin/mcq-questions/',
    getMcq : '/admin/mcq-questions',
    deleteMcq : '/admin/mcq-questions/',
    addMcq : '/admin/mcq-questions/add-question-to-exam',
    addBulkMcq: '/admin/mcq-questions/add-bulk-question-to-exam',
    getTestsMcq: '/admin/mcq-questions/get-tests?courseId=',

    getBrandPartner: '/admin/brand-partners?',
    getCouponBrandPartner: '/admin/brand-partners/get-coupons',
    setBrandpartnerCoupon: '/admin/brand-partners/',
    searchBrandPartner: '/admin/brand-partners/search?',
    setBrandPartnerAndCreateCouponfromExcel : '/admin/brand-partners/create-brandPartner',

    getEducator:'/admin/educators?',
    setEducator: '/admin/educators/',
    searchEducator: '/admin/educators/search?',


    liveClassGetGoal :  '/admin/live-classes/get-goals',
    liveClassGetSubs : '/admin/live-classes/get-subscriptions',
    liveClassGetSubscriptionByCourse : '/admin/live-classes/get-subscriptions-by-courseId/',
    liveClassGetCourse : '/admin/live-classes/get-courses',
    liveClassGetSubject : '/admin/live-classes/get-subjects',
    liveClassGetTeacher : '/admin/live-classes/get-teachers',
    liveClassCreate : '/admin/live-classes',
    liveClassGet    : '/admin/live-classes?',
    liveClassesOfCatalogCourse: '/admin/live-classes/catalog-courses?',
    liveClassUpdate : '/admin/live-classes/',
    liveClassFilter : '/admin/live-classes/search-by-date',
    CatalogCourseliveClassFilter : '/admin/live-classes/filter-catalogCourse-liveClass-by-date',
    liveClassDelete : '/admin/live-classes/',
    liveClassSearchByTitle : '/admin/live-classes/search-liveClass?',
    CatalogCourseliveClassSearchByTitle : '/admin/live-classes/filter-catalogCourse-liveClass-by-title?',

    affiliateList : '/admin/affiliate-payments',
    affiliatePaymentCreate : '/admin/affiliate-payments',
    affiliatePaymentDelete : '/admin/affiliate-payments/',
    affiliateUpdateAmount : '/admin/affiliate-payments/',

    affiliateSearchDue : '/admin/affiliate-payments/total-dues',

    createPayment : '/admin/payments',
    paymentGetSubscription : '/admin/payments/get-subscriptionPlans?subscriptionId=',
    paymentAddUser : '/admin/payments/payment-addto-user',
    paymentGetlist : '/admin/payments?',
    paymentSearchByDate : '/admin/payments/search-by-date?',

    orderCreate :  '/admin/orders/',
    orderDelete : '/admin/orders/delete',
    orderSearch :  '/admin/orders/search?',
    orderListGet : '/admin/orders?',
    orderGetGoals : '/admin/orders/get-goals',
    orderGetSubscriptions : '/admin/orders/get-subscriptions',
    orderCreateForBulkUser : '/admin/orders/bulk-user-orderCreate',
    createCatalogCourseOrder : '/admin/orders/bulk-user-create-catalogCourse',
    increaseCatalogCourseLimitOrder: '/admin/orders/bulk-user-catalogCourse-limit',
    subscriptionIncreaseBulkUserOrder : '/admin/orders/bulk-user-subscription-limit',

    updateExam : '/admin/mcq-exams/add-quiz-to-class?',

    SmsSend : '/admin/send-sms/bulk-sms-to-selected-users?',
    SmsSendCustomUser : '/admin/send-sms/custom-number?',
    SmsSendSingleUser : '/admin/send-sms/single-user?',
    SmsSendToAllUser : '/admin/send-sms/bulk-sms-to-all-users?',
    smsSerachSingleUser: '/admin/send-sms/search-by-phone?phone=',
    smsGetGoals: '/admin/send-sms/get-goals',
    smsGetSubscriptions: '/admin/send-sms/get-subscriptions',
    smsSendRoleWise : '/admin/send-sms/role-wise',
    smsSendDynamic : '/admin/send-sms/dynamic-bulk-sms',

    getFilterGoals: '/admin/content-filter/get-goals',
    getFilterFreeCourses: '/admin/content-filter/free-course?goalId=',
    getFilterSubscriptions: '/admin/content-filter/get-subscriptions-by-goal?goalId=',
    getFilterActiveSubscriptions: '/admin/content-filter/get-active-subscriptions-by-goal?goalId=',
    getFilterInActiveSubscriptions: '/admin/content-filter/get-inActive-subscriptions-by-goal?goalId=',
    getFilterCourses: '/admin/content-filter/get-courses-by-subscription?subscriptionId=',
    getFilterSubjects: '/admin/content-filter/get-subjects-by-course?courseId=',
    getFilterChapters: '/admin/content-filter/get-chapters-by-subject?subjectId=',
    getFilterClasses: '/admin/content-filter/get-classes-by-chapter?chapterId=',
    getFilterCatalogCourses: '/admin/content-filter/catalog-course?goalId=',

    getCourseExplorerCourses: '/admin/course-explorer/course-summary?subscriptionId=',
    getCourseExplorerCoursesByTitle: '/admin/course-explorer/search-course?title=',
    getCourseExplorerFreeCourses: '/admin/course-explorer/free-course-summary?goalId=',
    getCourseExplorerCatalogCourses: '/admin/course-explorer/catalog-course-summary?goalId=',
    getCourseExplorerEducators: '/admin/course-explorer/get-educators',
    addCourseToSubscription: '/admin/course-explorer/add-course-to-subscription',
    addFreeCourseToGoal: '/admin/course-explorer/add-free-course-to-goal',
    addCatalogCourseToGoal: '/admin/course-explorer/add-catalog-course-to-goal',
    addSubjectToCourse: '/admin/course-explorer/add-subject-to-course',
    addChapterToSubject: '/admin/course-explorer/add-chapter-to-subject',
    addClassToChapter: '/admin/course-explorer/add-class-to-chapter',
    getQuizByOldSubject: '/admin/course-explorer/get-quiz-by-old-subject?subjectId=',
    getQuizById: '/admin/course-explorer/get-quiz/',
    addQuizToClass: '/admin/course-explorer/add-quiz-to-class',
    getCourseExplorerDoubtSolvers: '/admin/course-explorer/get-doubtSolvers',
    getCourseExplorerExamReviewers: '/admin/course-explorer/get-examReviewers',
    getCourseExplorerDoubtSolversByCourse: '/admin/course-explorer/get-doubtSolvers-by-course/',
    getCourseExplorerDoubtSolversByCourseSubject: '/admin/course-explorer/get-doubtSolvers-by-course-subject',
    getCourseExplorerExamReviewerByCourseSubject: '/admin/course-explorer/get-examReviewer-by-course-subject/',
    addSubjectToDoubtSolver: '/admin/course-explorer/add-subject-to-doubtSolver',
    addSubjectToExamReviewer: '/admin/course-explorer/add-subject-to-examReviewer',
    deleteDoubtSolverFromSubject : '/admin/course-explorer/remove-subject-from-doubtSolver',
    deleteExamReviewerFromSubject : '/admin/course-explorer/remove-subject-from-examReviewer',

    uploadSubjectBanner: '/admin/upload/subjectBanner/',
    uploadSubscriptionBanner : '/admin/upload/subscriptionBanner/',
    uploadCourseBanner: '/admin/upload/courseBanner/',

    deleteDoubt : '/admin/doubts/',
    getDoubts : '/admin/doubts?',
    getUserDoubtsByPhone : '/admin/doubts/user-by-phone?',
    getSolvedDoubts : '/admin/doubts/solved-by-user?',
    getDoubtSolverList: '/admin/doubts/solver-list',
    getDoubtExplorerAssignedSubjects: '/admin/doubt-explorer/assignedSubjects',
    getDoubtsBySubjectInCourse: '/admin/doubts?',
    getDoubtById: '/admin/doubts/details/',
    updateDoubtById: '/admin/doubts/',
    getDoubtFilterWise : '/admin/doubts/filter-doubt?',

    walletUpdate : '/admin/users/walletUpdate',
    getExamExplorerCourseExams: '/admin/exam-explorer/exams-by-course/',
    getExamExplorerFilterWrittenExams: '/admin/written-exams/writtenExams-filter',
    getExamExplorerCourseQuizExams: '/admin/exam-explorer/exams-by-class/',
    getExamExplorerExamById: '/admin/exam-explorer/exam/',
    getExamExplorerWrittenExamById: '/admin/written-exams/examById/',
    examExplorerNewExam: '/admin/exam-explorer/exam',
    examExplorerNewWrittenExam: '/admin/written-exams/create',
    examExplorerUpdateExam: '/admin/exam-explorer/exam/',
    examExplorerUpdateWrittenExam: '/admin/written-exams/update/',
    examExplorerDeleteExam: '/admin/exam-explorer/exam/',
    examExplorerDeleteWrittenExam: '/admin/written-exams/delete/',
    examExplorerGetQuestions: '/admin/exam-explorer/exam-questions/',
    getExamExplorerFilterWrittenExamResults : '/admin/written-exams/filter-writtenExam?',
    /* Public exams */
    getExamExplorerPublicExams: '/admin/exam-explorer/public-exams',
    examExplorerCreatePublicExam: '/admin/exam-explorer/public-exam',
    
    userGetWrittenExamsResult: '/admin/written-exams/user-examResult',
    userWrittenExamDelete: '/admin/written-exams/result-delete',

    adminSendNotification: '/admin/notification/send',
    adminSendNotice: '/admin/notice/send',

    getAllCatalogCourse: '/admin/courses/catalog-courses'
}

export default api