import React, { useEffect, useRef, useState } from "react"

/* @Mui component */
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MultiSelectorChip from "../../../Components/MultiSelectorChip"
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import RestoreIcon from '@mui/icons-material/Restore'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListItemText from '@mui/material/ListItemText'
import Divider from "@mui/material/Divider"
import EditIcon from '@mui/icons-material/Edit';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import UpdateEbook from "../Course/UpdateEbook"
import RichTextEditor from "../../../Components/RichTextEditor"
import BasicCircularProgress from "../../../Components/BasicCircularProgress"
import { useSnackbar } from "notistack"

/* @CUSTOM COMPONENT */
import axios from "../../../axios"
import api from "../../../api"
import "../../../Css/root.css"
import DoubtSolverDialog from "../Course/DoubtSolverDialog"
import SimpleDeleteModal from "../../../Components/SimpleDeleteModal"

const courseServices = [
    {
        name: "Recorded Class",
        _id: "recorded"

    },
    {
        name: "Live Class",
        _id: "live"

    },
    {
        name: "Quiz",
        _id: "quiz"
    },
    {
        name: "Exam",
        _id: "exam"
    },
    {
        name: "Ebook",
        _id: "ebook"
    },
    {
        name: "Doubt",
        _id: "doubt"
    }
]
const fileUploadconfig = {
    headers: {
        "x-auth-token": localStorage.getItem("user"),
        "Content-Type": "multipart/form-data"
    }
}
const TAG = "CourseAddUpdateDialog"
const generateKey = (pre) => {
    return `${TAG}_${pre}_${new Date().getTime()}`
}
const Input = styled('input')({
    display: 'none',
})

export default function CatalogCourseAddUpdateDialog(props) {
    const [courseLoading, setCourseLoading] = useState(false)
    const [educatorLoading, setEducatorLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [ebookLoading, setEbookLoading] = useState(false)
    const [educators, setEducators] = useState([])
    const [subjects, setSubjects] = useState([])

    const [title, setTitle] = useState('')
    const [introVideo , setIntroVideo] = useState("")
    const [order, setOrder] = useState('')
    const [isAppOnly, setIsAppOnly] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [isFree, setIsFree] = useState(false)
    const [description, setDescription] = useState('')
    const [selectedEducators, setSelectedEducators] = useState([])
    const [bannerFile, setBannerFile] = useState('')
    const [isTrialEnabled, setIsTrialEnabled] = useState(false)
    const [trialPeriod, setTrialPeriod] = useState(0)

    const [openSolverDialog, setOpenSolverDialog] = useState(false)
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedEbook, setSelectedEbook] = useState([])
    const [ebookFile, setEbookFile] = useState({ isShow: false, pdf: {}, thumbnai: {}, title: '' })
    const [banner, setBanner] = useState('')
    const [updateEbook, setUpdateEbook]=useState('')

    const [deleteModalEbookId, setDeleteModalEbookId] = useState('');

    //  Social Link State 
	const [facebookLink, setFacebookLink] = useState('')
	const [youtubeLink, setYoutubeLink] = useState('')
	const [whatsappLink, setWhatsappLink] = useState('')
	const [telegramLink, setTelegramLink] = useState('')

    const [errorMessage, setErrorMessage] = useState({})
    const editorRef = useRef(null)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const goalId = props.goalId

    const config = {
        headers: {
            "x-auth-token": localStorage.getItem("user"),
        }
    }

    const servicesData = {
        title: "Services",
        items: courseServices,
        index: "name",
        selected: selectedServices
    }


    const educatorData = {
        title: "Educator",
        items: educators,
        index: "name",
        selected: selectedEducators
    }

    function resetFormData() {
        setTitle('')
        setOrder('')
        setIsActive(true)
        setIsFree(false)
        setDescription('')
        setSelectedEducators([])
        setErrorMessage({})
    }

    const handleServiceSelection = (value) => {
        if (!value.length) {
            setErrorMessage(message => {
                return { ...message, service: 'Select at least one service.' }
            })
        } else {
            setErrorMessage(message => {
                return { ...message, service: '' }
            })
        }
        setSelectedServices(value)

        if (value.includes('ebook')) {
            setEbookFile({ isShow: true, pdf: {}, thumbnai: {} })
        } else {
            setEbookFile({ isShow: false, pdf: {}, thumbnai: {} })
        }
    }

    const handleSelection = (value) => {
        if (!value.length) {
            setErrorMessage(message => {
                return { ...message, educator: 'Select at least one teacher.' }
            })
        } else {
            setErrorMessage(message => {
                return { ...message, educator: '' }
            })
        }
        setSelectedEducators(value)
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleReset = () => (
        resetFormData()
    )

    const handleSubmit = () => {
        if (!isFormDataValid()) return
        props.courseId ? updateCourse() : addNewCourse()
    }

    const isFormDataValid = () => {
        var isValid = true
        if (!title.length) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, title: 'Title must not be empty.' }
            })
        }
        if(introVideo) {
            if(introVideo.includes("watch?v")){
                isValid = false
                setErrorMessage(message => {
                    return {...message, introVideo: 'Please enter valid youtube video.'}
                })
            }
        }
        if (!order) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, order: 'Order must be a valid number' }
            })
        }
        if (!trialPeriod) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, trialPeriod: 'Trial period must be a valid number' }
            })
        }
        if (!selectedEducators.length) {
            isValid = false
            setErrorMessage(message => {
                return { ...message, educator: 'Select at least one teacher.' }
            })
        }
        if (editorRef.current && editorRef.current.getContent() === '') {
            isValid = false
            enqueueSnackbar("Description can not be empty", { variant: 'error' })
        }
        return isValid
    }

    const deleteEbook = async(ebookId)=>{
        if(!ebookId) return enqueueSnackbar("Invalid ebook", {variant: 'error'})
        setEbookLoading(true)
        try{
            const response = await axios.delete(api.deleteEbook + `?ebookId=${ebookId}&courseId=${props.courseId}`, config)
            enqueueSnackbar(response.data, {variant: 'success'})
            setEbookLoading(false)
            setDeleteModalEbookId('')
            fetchCourseData(props.courseId)
        }catch(error){
            enqueueSnackbar(error.response?.data, {variant: 'error'})
            setEbookLoading(false)
            setDeleteModalEbookId('')
        }
    }

    async function fetchCourseData(courseId) {
        setCourseLoading(true)
        axios
            .get(api.getCourseById + courseId, config)
            .then((response) => {
                setTitle(response.data.title)
                setIntroVideo(response?.data?.introVideo)
                setIsActive(response.data.isActive)
                setIsFree(response.data.isFree)
                setDescription(response.data.description)
                setSelectedEducators(response.data.teachers)
                setSelectedServices(response.data.services)
                setSubjects(response?.data?.subjects)
                if (response.data.services.includes('ebook')) {
                    setEbookFile({ ...ebookFile, isShow: true });
                }
                setSelectedEbook(response.data.ebooks);
                if (response.data.order) setOrder(response.data.order);
                if (response.data.banner) setBanner(response.data.banner);
                if(response?.data?.isAppOnly) setIsAppOnly(response?.data?.isAppOnly)

                //set social state
                if (response.data?.social?.length > 0) {
                  let social = response.data.social;
                  const facebook = social.filter(
                    (item) => item.type === "facebook"
                  );
                  if (facebook[0]?.url) {
                    setFacebookLink(facebook[0].url)
                  }
                  const youtube = social.filter(
                    (item) => item.type === "youtube"
                  );
                  if (youtube[0]?.url) {
                    setYoutubeLink(youtube[0].url)
                  }
                  const whatsapp = social.filter(
                    (item) => item.type === "whatsapp"
                  );
                  if (whatsapp[0]?.url) {
                    setWhatsappLink(whatsapp[0].url)
                  }
                  const telegram = social.filter(
                    (item) => item.type === "telegram"
                  );
                  if (telegram[0]?.url) {
                    setTelegramLink(telegram[0].url)
                  }
                }
                if(response.data.freeTrial != null) {
                    setIsTrialEnabled(response.data.freeTrial.isEnabled)
                    setTrialPeriod(response.data.freeTrial.duration)
                }
                setCourseLoading(false);
            })
            .catch((error) => {
                enqueueSnackbar("Course data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewEbook(courseId) {
        if (!ebookFile.pdf && !ebookFile.title) return enqueueSnackbar("Ebook file pdf and title required", { variant: 'error' })
        let formData = new FormData();
        formData.append("ebook", ebookFile.pdf);
        formData.append("thumbnail", ebookFile.thumbnai);
        formData.append("title", ebookFile.title);
        axios
            .post(api.AddNewEbook + courseId, formData, fileUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
            })
            .catch((error) => {
                enqueueSnackbar("Upload ebook failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function uploadCourseBanner(courseId) {
        if (!bannerFile) return
        let formData = new FormData()
        formData.append("banner", bannerFile)

        axios.post(api.uploadCourseBanner + courseId, formData, fileUploadconfig)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
            })
            .catch((error) => {
                enqueueSnackbar("Upload course banner failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchEducators() {
        setEducatorLoading(true)
        axios
            .get(api.getCourseExplorerEducators, config)
            .then((response) => {
                setEducators(response.data)
                setEducatorLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar("Educator fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function addNewCourse() {
        setSubmitLoading(true)
        let coursePayload = {
            title: title,
            description: editorRef.current.getContent(),
            isAppOnly: isAppOnly,
            category: {
                value: 'catalog',
                parent: props.goalId
            },
            subjects: [],
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators,
            services: selectedServices,
            social: [],
            freeTrial: {
                isEnabled: isTrialEnabled,
                duration: trialPeriod
            }
        }
        if (isFree) {
            coursePayload.category.value = "free"
        }
        if(introVideo) coursePayload.introVideo = introVideo 
        if(facebookLink) coursePayload.social.push({type: 'facebook', url: facebookLink})
		if(youtubeLink) coursePayload.social.push({type: 'youtube', url: youtubeLink})
		if(whatsappLink) coursePayload.social.push({type: 'whatsapp', url: whatsappLink})
		if(telegramLink) coursePayload.social.push({type: 'telegram', url: telegramLink})
        axios
            .post(api.createCourse, coursePayload, config)
            .then((response) => {
                let courseId = response.data
                axios
                    .post(
                        api.addCatalogCourseToGoal,
                        {
                            courseId: response.data,
                            goalId: goalId
                        },
                        config
                    )
                    .then((response) => {
                        enqueueSnackbar(response.data, { variant: 'success' })
                        addNewEbook(courseId)
                        uploadCourseBanner(courseId)
                        setSubmitLoading(false)
                        handleClose()
                        props.handleSuccess()
                    })
                    .catch((error) => {
                        enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
                    })
            })
            .catch((error) => {
                enqueueSnackbar("Add new course failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function updateCourse() {
        let updatePayload = {
            title: title,
            description: editorRef.current.getContent(),
            order: order,
            isFree: isFree,
            isActive: isActive,
            teachers: selectedEducators,
            services: selectedServices,
            social: [],
            isAppOnly: isAppOnly,
            category: {
                value: 'catalog',
                parent: props.goalId
            },
            freeTrial: {
                isEnabled: isTrialEnabled,
                duration: trialPeriod
            }
        }
        if (isFree) {
            updatePayload.category.value = "free"
        }
        if(introVideo) updatePayload.introVideo = introVideo 
        if(facebookLink) updatePayload.social.push({type: 'facebook', url: facebookLink})
		if(youtubeLink) updatePayload.social.push({type: 'youtube', url: youtubeLink})
		if(whatsappLink) updatePayload.social.push({type: 'whatsapp', url: whatsappLink})
		if(telegramLink) updatePayload.social.push({type: 'telegram', url: telegramLink})

        axios
            .put(api.updateCourse + props.courseId, updatePayload, config)
            .then((response) => {
                enqueueSnackbar(response.data, { variant: 'success' })
                if (ebookFile.isShow && ebookFile.pdf && ebookFile.thumbnai && ebookFile.title) {
                    addNewEbook(props.courseId)
                }
                uploadCourseBanner(props.courseId)
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Update course failed: " + error.response.data, { variant: 'error' })
            })
    }
    const onUploadBanner = ({ target }) => {
        if (target.files[0]) {
            setBannerFile(target.files[0])
        }
    }

    const onUploadEbookThumbnail = ({ target }) => {
        setEbookFile({ ...ebookFile, thumbnai: target.files[0] })
    }

    const onUploadEbookPdf = ({ target }) => {
        setEbookFile({ ...ebookFile, pdf: target.files[0] })
    }

    useEffect(() => {
        // TODO: Stop initial loading
        if (!props.open) {
            return
        }
        props.courseId ? fetchCourseData(props.courseId) : setCourseLoading(false)
        fetchEducators()
    }, [props.open])

    const appBar = (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Add or Update Course
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={handleSubmit}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
                <LoadingButton
                    color="inherit"
                    sx={{ ml: 2 }}
                    onClick={handleReset}
                    loading={courseLoading || educatorLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<RestoreIcon />}
                    variant="contained"
                >
                    Reset
                </LoadingButton>
            </Toolbar>
        </AppBar>
    )

    const titleField = (
        <TextField
            required
            error={!!errorMessage.title}
            variant="outlined"
            label="Title"
            helperText={errorMessage.title}
            sx={{ minWidth: 300 }}
            value={title}
            onChange={(event) => {
                const text = event.target.value
                if (!text.length) {
                    setErrorMessage(message => {
                        return { ...message, title: 'Title must not be empty.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, title: '' }
                    })
                }
                setTitle(event.target.value)
            }}
        />
    )
    const introField = (
        <TextField
            required
            error={!!errorMessage.introVideo}
            variant="outlined"
            label="Intro Video"
            helperText={errorMessage.introVideo}
            sx={{ minWidth: 300 }}
            value={introVideo}
            onChange={(event) => {
                setIntroVideo(event.target.value)
            }}
        />
    )
    const orderField = (
        <TextField
            required
            error={!!errorMessage.order}
            variant="outlined"
            type="number"
            label="Order"
            helperText={errorMessage.order}
            sx={{ minWidth: 300 }}
            value={order}
            onChange={(event) => {
                const order = event.target.value
                if (!order) {
                    setErrorMessage(message => {
                        return { ...message, order: 'Order must be a valid number.' }
                    })
                } else {
                    setErrorMessage(message => {
                        return { ...message, order: '' }
                    })
                }
                setOrder(event.target.value)
            }}
        />
    )

    const appOnlyField = (
        <FormGroup>
            <FormControlLabel
                onChange={(event) => setIsAppOnly(event.target.checked)}
                control={<Checkbox checked={isAppOnly} />}
                label="Available only for app"
            />
        </FormGroup>
    )

    const freeTrialField = (
        <FormGroup>
            <FormControlLabel
                label="Enable Free Trial"
                sx={{ marginBottom: 2 }}
                control={
                    <Switch
                        checked={isTrialEnabled}
                        onChange={(event) => setIsTrialEnabled(event.target.checked)}
                    />
                }
            />
            <TextField
                error={!!errorMessage.trialPeriod}
                variant="outlined"
                type="number"
                label="Trial Duration(In Days)"
                helperText={errorMessage.trialPeriod}
                sx={{ minWidth: 300 }}
                value={trialPeriod}
                onChange={(event) => {
                    const period = event.target.value
                    if (!period) {
                        setErrorMessage(message => {
                            return { ...message, trialPeriod: 'Trial period must be a valid number.' }
                        })
                    } else {
                        setErrorMessage(message => {
                            return { ...message, trialPeriod: '' }
                        })
                    }
                    setTrialPeriod(event.target.value)
                }}
            />
        </FormGroup>
    )

    const isActiveSwitch = (
        <FormControlLabel
            label="Is Active"
            control={
                <Switch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                />
            }
        />
    )

    const isFreeSwitch = (
        <FormControlLabel
            label="Is Free"
            control={
                <Switch
                    checked={isFree}
                    onChange={(event) => setIsFree(event.target.checked)}
                />
            }
        />
    )

    const educatorSelector = (
        <Box sx={{ maxHeight: 400, overflow: 'auto', p: 2 }}>
            <MultiSelectorChip
                required
                error={!!errorMessage.educator}
                helperText={errorMessage.educator}
                data={educatorData}
                handleSelection={handleSelection}
                sx={{ width: 400 }}
            />
        </Box>
    )

    const serviceSelector = (
        <Box sx={{ maxHeight: 400, overflow: 'auto', p: 2 }}>
            <MultiSelectorChip
                required
                error={!!errorMessage.service}
                helperText={errorMessage.service}
                data={servicesData}
                handleSelection={handleServiceSelection}
                sx={{ width: 400 }}
            />
        </Box>
    )

    const richTextEditor = (
        <RichTextEditor
            sx={{ width: 600, height: 600 }}
            initialValue={description}
            onInit={(_evt, editor) => {
                editorRef.current = editor
            }}
        />
    )
    const bannerUploadCard = (
        <Card sx={{ width: 200 }}>
            {
                bannerFile ?
                    <CardMedia
                        component="img"
                        height="118"
                        image={URL.createObjectURL(bannerFile)}
                    /> : (
                        banner ?
                            <CardMedia
                                component="img"
                                height="118"
                                image={"https://cdn.rootsedulive.com/" + banner}
                            /> : <Skeleton variant="rectangular" width={200} height={118} />
                    )
            }
            <CardActions disableSpacing>
                <label htmlFor="subject-banner">
                    <Input onChange={onUploadBanner} accept="image/*" id="subject-banner" type="file" />
                    <Button component="span" size="small">Upload Banner</Button>
                </label>
            </CardActions>
        </Card>
    )

    const selectedEbookList = (
        <Card elevation={2} sx={{ width: 400, p: 2 }}>
            <Typography variant="body1" gutterBottom align="center">
                Uploaded Ebook
            </Typography>
            <Divider/>
            <Box sx={{ p: 2 }}>
                {selectedEbook.map((value, index) => {
                    return (
                        <div key={index}>
                            <Stack sx={{ p: 1 }} direction={'row'} alignItems={'center'} spacing={3}>
                                <ListItemText id={value._id} primary={`${value.title}`} />
                                <IconButton onClick={() => window.open(`https://api.rootsedulive.com/bucket/${value.fileUrl}`)}>
                                    <OpenInNewIcon />
                                </IconButton>
                                <IconButton onClick={() => setUpdateEbook(value)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => setDeleteModalEbookId(value._id)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Stack>
                            <Divider />
                        </div>
                    );
                })}
            </Box>
        </Card>
    )

    const fileUploadCard = (
        <Card elevation={2} sx={{ width: 400, p: 2 }}>
            <Typography align="center" gutterBottom variant="h5" component="div">
                Upload Ebook
            </Typography>
            <TextField
                sx={{ width: '100%' }}
                required
                id="outlined-required"
                label="Ebook title"
                defaultValue=""
                onChange={(e) => setEbookFile({ ...ebookFile, title: e.target.value })}
            />
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-thumbnail">
                    <Input onChange={onUploadEbookThumbnail} accept="image/*" id="contained-button-file-thumbnail" type="file" />
                    <Button sx={{ mt: 2, width: '100%' }} variant="contained" component="span">
                        Upload ebook thumbnail
                    </Button>
                </label>

                {ebookFile.thumbnai.name ?
                    <Typography sx={{ mt: 2, width: '100%' }} gutterBottom variant="body" component="div">
                        Uploaded thumbnail : {ebookFile.thumbnai.name}
                    </Typography> : null
                }
            </Grid>
            <Grid sx={{ width: '100%' }}>
                <label htmlFor="contained-button-file-pdf">
                    <Input onChange={onUploadEbookPdf} accept=".pdf" id="contained-button-file-pdf" type="file" />
                    <Button sx={{ mt: 2 }} variant="contained" component="span">
                        Upload ebook pdf
                    </Button>
                </label>

                {ebookFile.pdf.name ?
                    <Typography sx={{ mt: 2 }} gutterBottom variant="body" component="div">
                        Uploaded pdf  : {ebookFile.pdf.name}
                    </Typography> : null
                }

            </Grid>
        </Card>
    )

    const socialLink = (
      <Card elevation={2} sx={{ width: 400, p: 2 }}>
        <Stack spacing={3}>
          <Typography align="center" gutterBottom variant="h5" component="div">
            Social Link
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            required
            id="outlined-required"
            label="Facebook"
            value={facebookLink}
            onChange={(e) => setFacebookLink(e.target.value)}
          />
          <TextField
            sx={{ width: "100%" }}
            required
            id="outlined-required"
            label="Youtube"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
          />
          <TextField
            sx={{ width: "100%" }}
            required
            id="outlined-required"
            label="Whatsapp"
            value={whatsappLink}
            onChange={(e) => setWhatsappLink(e.target.value)}
          />
          <TextField
            sx={{ width: "100%" }}
            required
            id="outlined-required"
            label="Telegram"
            value={telegramLink}
            onChange={(e) => setTelegramLink(e.target.value)}
          />
        </Stack>
      </Card>
    );


    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {(courseLoading || educatorLoading || ebookLoading) ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
                        <Stack direction="column" spacing={4}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={4}>
                                {titleField}
                                {orderField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {introField}
                                {isActiveSwitch}
                                {isFreeSwitch}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {appOnlyField}
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                {freeTrialField}
                            </Stack>
                            <Stack spacing={4} justifyContent="center" alignItems="center">
                                {educatorSelector}
                                {serviceSelector}
                                {selectedEbook.length > 0 ? selectedEbookList : null}
                                {ebookFile.isShow ? fileUploadCard : null}
                                {socialLink}
                            </Stack>
                        </Stack>
                        {richTextEditor}
                        <Stack direction="column" spacing={4}>
                            {bannerUploadCard}
                            {
                                selectedServices.includes("doubt") &&
                                <Button
                                    variant="contained"
                                    component="span"
                                    onClick={(_event) => setOpenSolverDialog(true)}
                                >
                                    Open Doubt Solver Menu
                                </Button>
                            }
                        </Stack>
                    </Stack>
                </Box>}
            <DoubtSolverDialog
                key={generateKey("solver-dialog")}
                open={openSolverDialog}
                courseId={props.courseId}
                subjects={subjects}
                handleClose={() => setOpenSolverDialog(false)}
            />
            {updateEbook && <UpdateEbook
                onCloseEbook={() => setUpdateEbook(null)}
                handleSuccess={()=>{setUpdateEbook(null);fetchCourseData(props.courseId)}}
                ebookId={updateEbook._id}
                title={updateEbook.title}
                open={updateEbook ? true : false}
            />}
            <SimpleDeleteModal
                title="Delete Ebook"
                open={deleteModalEbookId ? true : false}
                handleClose={() => {
                    setDeleteModalEbookId('')
                }}
                handleDelete={() => deleteEbook(deleteModalEbookId)}
            />
        </Dialog>
    )
}